import { Amplify, Auth, Storage } from 'aws-amplify';

Amplify.configure({
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:0ac94c59-404e-4bc9-8c9e-6ea62e1c8b30",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_038zyIDbQ",
    "aws_user_pools_web_client_id": "3tlnavhrm760i091jkbihtvdrb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ivacay-files94933-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
});

import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

// Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});

let userAuth = {
    login: '',
    pw: '',
    pw2: '',
}

let loading = false;
let error = null;
let login_result = null;

let awsLogin = async function() {
    userAuth.login = document.getElementById("login_form_email").value;
    userAuth.pw = document.getElementById("login_form_password").value;

    try {
        login_result = await Auth.signIn(userAuth.login, userAuth.pw);
        console.log('login_result:', login_result);

        const user1 =  await Auth.currentAuthenticatedUser();
        
        // console.log('awsLogin:', login_result);
        if(login_result?.signInUserSession?.accessToken?.jwtToken && user1.signInUserSession.accessToken?.payload["cognito:groups"]?.includes("Editors")){
            // window.location.replace(`https://dashboard.dpv7rf89kfefr.amplifyapp.com?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
            // window.location.replace(`http://localhost:8080?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
            window.location.replace(`https://admin.ivacay.co?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
        } else if(login_result?.signInUserSession?.accessToken?.jwtToken){
            window.location.replace(`https://www.ivacay.co/`);
        }
    } catch(err) {
        console.log(err);
        error = err;
    } finally {
        loading = false;
        // this.$emit('bridge', { login: this.userAuth.login, pw: this.userAuth.pw });
    }

    // console.log("LOGIN", btoa(`${userAuth.login}:${userAuth.pw}`));
}

let ourBackLogin = async function(){
    userAuth.login = document.getElementById("login_form_email").value;
    userAuth.pw = document.getElementById("login_form_password").value;

    var settings = {
        "url": "https://app.ivacay.co/api/auth/signin",
        "method": "POST",
        "timeout": 0,
        "headers": {
          "secret": "secret-key",
          "Content-Type": "application/json"
        },
        "data": JSON.stringify({
          "email": userAuth.login,
          "password": userAuth.pw
        }),
    };

    $.ajax({
        ...settings,
        success: function (response) {
            if(response?.accessToken){
                window.location.replace(`https://app.ivacay.co/dashboard/sign-in?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
            }
            return response;
        },
        error: function (err) {
            return err
        },
    });
}

if(document.querySelector('.login_aws_btn')){
    document.querySelector('.login_aws_btn').addEventListener('click', async function(e) {
        e.preventDefault();
        let ourBackLoginRes = await ourBackLogin();
        console.log('ourBackLoginRes:', ourBackLoginRes);
        if(ourBackLoginRes?.accessToken){
            window.location.replace(`https://app.ivacay.co/dashboard/sign-in?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
            // window.location.replace(`http://localhost:5173/dashboard/sign-in?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
        } else {
            await awsLogin(); 
        }
    });
}


let isVerify = false;

let verifyEmail = async function(){
    try {
        await Auth.confirmSignUp(userAuth.login, document.getElementById("login_form_code").value);
        
        login_result = await Auth.signIn(userAuth.login, userAuth.pw);
        // console.log('verifyEmail login_result:', login_result);
        const user1 =  await Auth.currentAuthenticatedUser();
        console.log('user1:', user1);

        if(login_result?.signInUserSession?.accessToken?.jwtToken && user1.signInUserSession.accessToken?.payload["cognito:groups"]?.includes("Editors")){
            // window.location.replace(`http://localhost:8080?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
            window.location.replace(`https://admin.ivacay.co?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
        } else if(login_result?.signInUserSession?.accessToken?.jwtToken){
            window.location.replace(`https://www.ivacay.co/`);
        }
    } catch (error) {
        alert('Invalid verification code provided');
    } finally {
        loading = false;
    }
}

let awsSignUp = async function(){
    loading = true;
    error = null;

    userAuth.login = document.getElementById("login_form_email").value;
    userAuth.pw = document.getElementById("login_form_password").value;
    userAuth.pw2 = document.getElementById("login_form_password_2").value;

    if(document.querySelector(".im-a-guide-radio") && document.querySelector(".im-a-guide-radio").checked){
        var settings = {
            "url": "https://app.ivacay.co/api/auth/signup",
            "method": "POST",
            "timeout": 0,
            "headers": {
              "secret": "secret-key",
              "Content-Type": "application/json"
            },
            "data": JSON.stringify({
                "username": userAuth.login,
                "email": userAuth.login,
                "password": userAuth.pw,
                "confirm_password": userAuth.pw,
                "roles": ["guider"]
            }),
        };
    
        $.ajax({
            ...settings,
            success: function (response) {
                console.log('response:', response);
                if(response?.message?.includes("registered successfully")){
                    window.location.replace(`https://app.ivacay.co/dashboard/sign-in?login_data=${window.btoa(`${userAuth.login}:${userAuth.pw}`)}`);
                }
                return response;
            },
            error: function (err) {
                return err
            },
        });
    } else {
        if(isVerify){
            await verifyEmail();
        } else {
            if(userAuth.pw !== userAuth.pw2) {
                // this.emitError();
                return
            }
            try {
                await Auth.signUp({
                    username: userAuth.login,
                    password: userAuth.pw,
                    attributes: {
                        email: userAuth.login,
                    }
                });

                isVerify = true;

                document.querySelector('.signup-section__bottom').classList.add('d-none');
                document.querySelector('.code-verification-section__bottom').classList.remove('d-none');
            } catch(err) {
                console.log(err);
                error = err;
            } finally {
                loading = false;
            }
        }
    }
}

if(document.querySelector('.sign_up_aws_btn')){
    document.querySelector('.sign_up_aws_btn').addEventListener('click', async function(e) {
        e.preventDefault();
        await awsSignUp();
    });
}

if(document.querySelector('.sign_up_aws_btn_code')){
    document.querySelector('.sign_up_aws_btn_code').addEventListener('click', async function(e) {
        e.preventDefault();
        await awsSignUp();
    });
}

const getImageSrc = async(value) => {
    if(value.includes('uploaded-image-key:')){
        // console.clear();
        return await Storage.get(value.split('uploaded-image-key:')[1]);
    } if(value.includes('uploaded-video-key:')){
        // console.clear();
        return await Storage.get(value.split('uploaded-video-key:')[1]);
    } else {
        return value;
    }
};

let renderImages = async function(){
    if(document.querySelector('body').classList.contains('rendered')){
        let bgImageElements = document.querySelectorAll('[data-style-bg-image]');
        if(bgImageElements.length){
            bgImageElements.forEach(async element => {
                let imageUrl = await getImageSrc(element.getAttribute('data-style-bg-image'));
                element.style.backgroundImage = `url(${imageUrl})`;
            });
        }

        let imagesElements = document.querySelectorAll('img');
        if(imagesElements.length){
            imagesElements.forEach(async element => {
                element.src = await getImageSrc(element.src);
            });
        }

        let videoElements = document.querySelectorAll('video');
        if(videoElements.length){
            videoElements.forEach(async element => {
                element.poster = await getImageSrc(element.poster);
                let videoSource = element.querySelector('source');
                element.src = await getImageSrc(videoSource.src);
            });
        }
    } else {
        setTimeout(async () => {
            await renderImages();
        }, 300);
    }
}

window.addEventListener('DOMContentLoaded', async (event) => {
    await renderImages();
});
